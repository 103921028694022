import { errorType } from "./constants";

// A mapping of error keys to user-friendly messages
const ERROR_MESSAGES = {
  TRANSACTIONS_CONFIG_UPDATE_CART:
    "Invalid cart data in the update configuration.",
  TRANSACTIONS_CONFIG_CREATE_CART:
    "Invalid cart data for new transaction creation.",
  UNKNOWN_ERROR: "An unknown error occurred. Please check your data.",
  NETWORK_ERROR: "A network error occurred. Please try again later.",
  DUPLICATE_CAMPAIGN_NAME: "Campaign Name already Exists!",
};

// Centralized error handling function
export const getCampaignOrderErrorMessage = error => {
  const messages = [];

  // Check for GraphQL errors
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    error.graphQLErrors.forEach(graphqlError => {
      if (errorType.campaignOrderDuplicate.includes(graphqlError.message)) {
        messages.push(ERROR_MESSAGES.DUPLICATE_CAMPAIGN_NAME);
      } else {
        messages.push(graphqlError.message || ERROR_MESSAGES.UNKNOWN_ERROR);
      }
    });
  }

  // Check for Network errors
  const networkError = error.networkError;
  if (
    networkError &&
    networkError.result &&
    Array.isArray(networkError.result.errors)
  ) {
    networkError.result.errors.forEach(networkErrorDetail => {
      const { message } = networkErrorDetail;
      if (message.includes(errorType.transactionsConfigUpdateCart)) {
        messages.push(ERROR_MESSAGES.TRANSACTIONS_CONFIG_UPDATE_CART);
      } else if (
        message.includes(errorType.transactionsConfigCreateCart) ||
        message.includes(errorType.transactionsConfigUpsertCart)
      ) {
        messages.push(ERROR_MESSAGES.TRANSACTIONS_CONFIG_CREATE_CART);
      } else {
        messages.push(ERROR_MESSAGES.UNKNOWN_ERROR);
      }
    });
  } else if (networkError) {
    messages.push(ERROR_MESSAGES.NETWORK_ERROR);
  }

  // Fallback if no specific error was found
  if (messages.length === 0) {
    messages.push(ERROR_MESSAGES.UNKNOWN_ERROR);
  }

  return messages;
};
